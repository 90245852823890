import { MenuItem } from "@app/types";
import { Images } from "@assets/images";

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const DEFAULT_DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const DEFAULT_MINUTES_SECONDS_FORMAT = "mm:ss";

export const DEFAULT_PAGE = 1;
export const DEFAULT_TOTAL_PAGES = 1;

export const ACCESS_TOKEN_KEY = "access_token";
export const USER_INFO_KEY = "user_info";

export const RECHARGE_COUNTDOWN_TIME = 15 * 60 * 1000; // 15 minutes, unit: ms

export enum Role {
  ADMIN = "admin",
  USER = "user",
}

export enum PathnameUrl {
  GPU_HUB = "/",
  RECHARGE = "/payment-history",
  IDENTITY_VERIFICATION = "/identity-verification",
  ORDER_MANAGEMENT = "/order-management",
  LOG_MANAGEMENT = "/log-management",
}

export enum LabelAdmin {
  GPU_HUB = "Thuê Máy Tính",
  RECHARGE = "Nạp tiền",
  IDENTITY_VERIFICATION = "Hợp Đồng Thuê Máy",
  ORDER_MANAGEMENT = "Quản lý đơn hàng",
  LOG_MANAGEMENT = "Quản lý Log",
}

export const MenuAdmin: readonly MenuItem[] = [
  {
    linkTo: PathnameUrl.GPU_HUB,
    icon: Images.NvidiaIcon.default,
    label: LabelAdmin.GPU_HUB,
  },
  {
    linkTo: PathnameUrl.RECHARGE,
    icon: Images.MoneyIcon.default,
    label: LabelAdmin.RECHARGE,
  },
  {
    linkTo: PathnameUrl.IDENTITY_VERIFICATION,
    icon: Images.IdentityCardIcon.default,
    label: LabelAdmin.IDENTITY_VERIFICATION,
  },
  {
    linkTo: PathnameUrl.ORDER_MANAGEMENT,
    icon: Images.Clipboard.default,
    label: LabelAdmin.ORDER_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.LOG_MANAGEMENT,
    icon: Images.Log.default,
    label: LabelAdmin.LOG_MANAGEMENT,
  },
];

export enum BootStatusCode {
  SUCCESS = 200,
  IMAGE_IS_OUT = 400,
  CANNOT_CREATE_RDP_FILE = 500,
}

export enum RechargeStatusCode {
  SUCCESS = 200,
  FAILED = 400,
}

export enum VerificationMethod {
  EMAIL = "email",
}
