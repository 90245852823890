import { SshCli } from "@app/types/ssh.type";
import HttpService from "@core/services/http/http.service";

class _SshService {
  public sshCliAddMangle(sshCliRequest: SshCli) {
    return HttpService.post<any>("/sshs/cli-add-mangle", {
      body: { ...sshCliRequest },
    });
  }

  public sshCliDeleteMangle(sshCliRequest: SshCli) {
    return HttpService.post<any>("/sshs/cli-delete-mangle", {
      body: { ...sshCliRequest },
    });
  }
}

const SshService = new _SshService();

export default SshService;
