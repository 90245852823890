import Button from "@app/components/button";
import { FormControl } from "@app/components/form-control";
import Input from "@app/components/input";
import NotificationModal from "@app/components/notification-modal";
import { addToast } from "@app/components/toast/toast.service";
import {
  ACCESS_TOKEN_KEY,
  NotificationStatus,
  Role,
  SystemMessage,
  USER_INFO_KEY,
} from "@app/constants";
import AccountService from "@app/services/http/account.service";
import AuthService from "@app/services/http/auth.service";
import NotificationService from "@app/services/http/notification.service";
import { openPortalDialog } from "@app/services/modal.service";
import { clearUser, storeUser } from "@app/store/auth/auth.action";
import { clearMyInfo, storeMyInfo } from "@app/store/my-info/my-info.action";
import { LoginInitialValues } from "@app/types";
import { loginValidationSchema } from "@app/validations";
import { Images } from "@assets/images";
import useObservable from "@core/hooks/use-observable.hook";
import StorageService from "@core/services/storage";
import { Form, Formik, FormikContextType } from "formik";
import { createRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { catchError, switchMap, throwError } from "rxjs";

function SignIn() {
  const formRef = createRef<FormikContextType<LoginInitialValues>>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { subscribeOnce } = useObservable();

  const handleSubmit = (values: LoginInitialValues) => {
    subscribeOnce(
      AuthService.login(values.username, values.password),
      (loginRes) => {
        if (loginRes.role === Role.USER) {
          StorageService.set(ACCESS_TOKEN_KEY, loginRes.jwt);
          StorageService.setObject(USER_INFO_KEY, {
            _id: loginRes._id,
            username: loginRes.username,
          });
          dispatch(storeUser(loginRes));

          subscribeOnce(
            NotificationService.getNotification().pipe(
              switchMap((notificationRes) => {
                if (notificationRes.status === NotificationStatus.SHOW) {
                  openPortalDialog(NotificationModal, {
                    title: notificationRes.title,
                    content: notificationRes.content,
                  });
                }

                return AccountService.getMyInfo().pipe(
                  catchError((err) => {
                    dispatch(clearUser());
                    dispatch(clearMyInfo());

                    return throwError(() => err);
                  })
                );
              })
            ),
            (data) => {
              dispatch(storeMyInfo(data));
              navigate("/");
            }
          );
        } else {
          addToast({
            text: SystemMessage.DO_NOT_PERMISSION,
            status: "inValid",
          });
        }
      }
    );
  };

  return (
    <div className="flex relative h-screen w-full bg-cover bg-no-repeat bg-center bg-[url(@assets/images/background.jpg)]">
      <div className="absolute h-screen w-full bg-black opacity-30"></div>
      <div className="flex max-[900px]:flex-col max-[900px]:gap-5 max-[900px]:justify-center w-full z-10">
        <div className="flex-1 max-sm:w-full max-[900px]:flex-none">
          <div className="w-10/12 h-full mx-auto flex-col flex items-center justify-center">
            <div>
              <img
                className="w-52 max-sm:w-36"
                src={Images.LogoPcRenderWhite.default}
                alt=""
              />
            </div>
            <div className="text-white italic mt-2 max-[900px]:hidden">
              &quot;Happy rendering&quot;
            </div>
            <div className="text-white mt-3 font-medium max-[900px]:hidden">
              Chào mừng bạn tới PCRENDER , chúng tôi rất vui mừng vì bạn đã ghé
              thăm website của chúng tôi !
            </div>
            <div className="text-white font-[14.4px] mt-1 max-[900px]:hidden">
              PCRENDER là dịch vụ cho thuê các máy chủ ,server ,vps ,pc cấu hình
              cao bao gồm GPU xử lý công việc của bạn 1 cách nhanh chóng ,thuận
              tiện .
              <br />
              Chỉ 1 click là bạn có thể tăng tốc máy tính của bạn bằng cách
              chuyển khối lượng công việc trên máy tính của bạn qua máy thuê của
              chúng tôi .
              <br />
              Dù bạn ở bất kỳ đâu ( hà nội ,hồ chí minh ,...) cũng có thể kết
              nối từ xa qua máy tính của chúng tôi qua trình điều khiển REMOTE
              DESKTOP !
              <br />
              Hân hạnh được phục vụ bạn !!!
            </div>
            <div className="text-white mt-3">
              <iframe
                className="w-[360px] h-[180px] max-[900px]:hidden"
                src="https://www.youtube.com/embed/c9W5mGHIDX0"
                title="Hướng dẫn sử dụng PCRENDER - website cho thuê máy tính cày view, giả lập, render ... tự động 24/7"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
            <div className="text-white min-[899px]:hidden">
              <a
                className="underline cursor-pointer"
                href="https://youtu.be/c9W5mGHIDX0"
                target="_blank"
                rel="noreferrer"
              >
                Hướng dẫn sử dụng
              </a>
            </div>
          </div>
        </div>
        <div className="max-sm:w-full flex-1 max-[900px]:flex-none">
          <div className="h-full mx-auto flex-col flex items-center justify-center">
            <div className="bg-[#FFFFFFDA] px-4 py-2 rounded-[10px]">
              <div className="p-4 max-[900px]:p-2 max-sm:p-1">
                <div className="mb-[7px] mt-3">
                  <img
                    className="w-48 max-sm:w-32 mx-auto"
                    src={Images.LogoPcRender.default}
                    alt=""
                  />
                </div>
                <div className="text-[12.8px] text-[#6C757D] mt-1 text-center mb-7">
                  High-Performance GPU Computer Rental Service
                </div>
                <Formik
                  initialValues={{ username: "", password: "" }}
                  onSubmit={handleSubmit}
                  innerRef={formRef}
                  validationSchema={loginValidationSchema}
                  validateOnChange
                  validateOnBlur
                >
                  <Form>
                    <FormControl name="username">
                      <Input
                        width="auto"
                        className="!w-[376px] max-sm:!w-[270px] !max-w-none mb-[22px] max-sm:!mb-[15px]"
                        inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
                        placeholder="Tên tài khoản..."
                        inputElement={
                          <i className="fa fa-user text-[#c0c4cc]"></i>
                        }
                        errorClassName="text-[#F56C6C] text-xs"
                      />
                    </FormControl>
                    <FormControl name="password">
                      <Input
                        width="auto"
                        className="!w-[376px] max-sm:!w-[270px] !max-w-none mb-[22px] max-sm:!mb-[15px]"
                        inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
                        placeholder="Mật khẩu..."
                        inputElement={
                          <i className="fa fa-lock text-[#c0c4cc]"></i>
                        }
                        errorClassName="text-[#F56C6C] text-xs"
                        type="password"
                      />
                    </FormControl>
                    <div className="mt-3 text-sm text-[#409EFF] text-right">
                      <a
                        href="https://api.pcrender.com/view/confirm-account"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Quên mật khẩu?
                      </a>
                    </div>
                    <Button
                      type="submit"
                      width="auto"
                      size="m"
                      containerClassName="mt-7 max-sm:!mt-3"
                      label="ĐĂNG NHẬP"
                      className="text-sm rounded-[20px] bg-[#409EFF] border-[#1E86E5] hover:bg-[#66B1FF] hover:border-[#66B1FF]"
                      labelClassName="font-medium"
                    />
                  </Form>
                </Formik>
                <div className="mt-[20px] max-sm:!mt-3 text-[13.6px] text-[#757575] text-center">
                  Bạn chưa có tài khoản?
                </div>
                <div className="text-[14.4px] text-[#66B1FF] text-center font-medium">
                  <span
                    className="cursor-pointer"
                    onClick={() => navigate("/register")}
                  >
                    Đăng ký
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
