import { ImageItem, SshCli } from "@app/types";
import { formatDate, formatMoneyVN } from "@app/utils/util";
import { Images } from "@assets/images";
import clsx from "clsx";
import Button from "../button";
import { addToast } from "../toast/toast.service";
import { openPortalDialog } from "@app/services/modal.service";
import WanModal from "../wan-modal";
import useObservable from "@core/hooks/use-observable.hook";
import SshService from "@app/services/http/ssh.service";

interface ComputerCardProps {
  image: ImageItem;

  index: number;

  onDeleteImage: (imageId: string) => void;

  onBootImage: (image: ImageItem) => void;

  onConnectImage: (imageId: string) => void;

  onStartImage: (imageId: string) => void;

  onExtendImage: (image: ImageItem) => void;

  onReConnect: (imageId: string) => void;
}

function ComputerCard({
  image,
  index,
  onDeleteImage,
  onBootImage,
  onConnectImage,
  onStartImage,
  onExtendImage,
  onReConnect,
}: ComputerCardProps) {
  const { subscribeOnce } = useObservable();

  const getImageBoot = () => {
    if (image.is_booting === 0 && image.is_installed === 0) {
      return Images.RocketIcon.default;
    }

    if (image.is_booting === 1 && image.is_installed === 0) {
      return Images.SandClockIcon.default;
    }

    if (image.is_booting === 0 && image.is_installed === 1) {
      return Images.RemoteDesktop.default;
    }
  };

  const getLabelBoot = () => {
    if (image.is_booting === 0 && image.is_installed === 0) {
      return "Bắt Đầu Thuê";
    }

    if (image.is_booting === 1 && image.is_installed === 0) {
      return "Chờ tạo máy…";
    }

    if (image.is_booting === 0 && image.is_installed === 1) {
      return "Kết Nối PC";
    }
  };

  const handleBootImage = () => {
    if (image.is_booting === 0 && image.is_installed === 0) {
      onBootImage(image);

      return;
    }

    if (image.is_booting === 1 && image.is_installed === 0) {
      addToast({ text: "Image đang được boot", status: "warn" });

      return;
    }

    if (image.is_booting === 0 && image.is_installed === 1) {
      onConnectImage(image._id);

      return;
    }
  };

  const handleChooseRouter = () => {
    const createSshModalObs = openPortalDialog(WanModal, image);

    createSshModalObs.afterClosed().subscribe((data) => {
      if (data?.wan) {
        const sshCliRequest: SshCli = {
          int_ip_address: image.int_ip_address,
          wan: data.wan.trim(),
          ssh_id: image.ssh?._id,
        };

        const wanChoice = data.wan.split(" - ")[0];

        //kiểm tra wan được chọn có phải mặc định không
        wanChoice.toUpperCase() === "WAN00"
          ? subscribeOnce(
              SshService.sshCliDeleteMangle(sshCliRequest),
              (result) => {
                addToast({ text: result.message });
              }
            )
          : subscribeOnce(
              SshService.sshCliAddMangle(sshCliRequest),
              (result) => {
                addToast({ text: result.message });
              }
            );
      }
    });
  };

  return (
    <div className="w-full mt-3 bg-white shadow-[0_2px_12px_0_rgba(0,0,0,10%)] pt-2 pb-4 px-4 relative">
      <div className="flex justify-between border-b border-solid border-[#E0E0E0] pb-2">
        <div className="flex items-center gap-2">
          <div className="w-3 h-3 bg-[#42A5F5] rounded-full"></div>
          <div className="text-gray-500 text-xs px-3 py-1 bg-[#EEE] rounded-[5px] border border-solid border-[#BDBDBD]">
            #{index + 1}
          </div>
        </div>
        <div>
          <Button
            label="Xóa"
            size="xs"
            theme="danger"
            width="fit-content"
            labelClassName="text-xs"
            className="px-3 rounded-xl"
            onClick={() => onDeleteImage(image._id)}
          />
        </div>
      </div>
      <div className="flex max-md:flex-col">
        <div>
          <div className="max-md:flex-col text-[12.8px] text-[#757575] mt-3 flex gap-5">
            <div>
              <div>
                <span>Package:&nbsp;&nbsp;&nbsp;</span>
                <span className="font-medium text-[#212121]">
                  {image.package.name}
                </span>
              </div>
              <div>
                <span>Status:&nbsp;&nbsp;&nbsp;</span>
                {image.package.is_available ? (
                  <span className="font-medium text-[#29ae2b]">
                    Server Ready
                  </span>
                ) : (
                  <span className="font-medium text-[#ff5151]">
                    Server Full
                  </span>
                )}
              </div>
              <div>
                <span>CPU:&nbsp;&nbsp;&nbsp;</span>
                <span>{image.package.cpu}</span>
              </div>
              <div>
                <span>GPU:&nbsp;&nbsp;&nbsp;</span>
                <span className="font-medium text-[#212121]">
                  {image.package.gpu}
                </span>
              </div>
              <div>
                <span>Installed:&nbsp;&nbsp;&nbsp;</span>
                <span
                  className={clsx("font-medium opacity-50 italic", {
                    "text-[#212121]": image.is_installed === 0,
                    "text-green-500": image.is_installed === 1,
                  })}
                >
                  {image.is_installed === 1 ? "Đã cài" : "Chưa cài"}
                </span>
              </div>
              <div>
                <span>Price per hour:&nbsp;&nbsp;&nbsp;</span>
                <span>
                  {image.package.price_per_hour &&
                    formatMoneyVN(image.package.price_per_hour)}{" "}
                  VNĐ
                </span>
              </div>
              <div>
                <span>Price per day:&nbsp;&nbsp;&nbsp;</span>
                <span>
                  {image.package.price_per_day &&
                    formatMoneyVN(image.package.price_per_day)}{" "}
                  VNĐ
                </span>
              </div>
              <div>
                <span>Price per month:&nbsp;&nbsp;&nbsp;</span>
                <span>
                  {image.package.price_per_month &&
                    formatMoneyVN(image.package.price_per_month)}{" "}
                  VNĐ
                </span>
              </div>
            </div>
            <div>
              <div>
                <span>ID:&nbsp;&nbsp;&nbsp;</span>
                <span className="font-medium text-[#212121]">{image._id}</span>
              </div>
              <div>
                <span>Name:&nbsp;&nbsp;&nbsp;</span>
                <span className="font-medium text-[#212121]">{image.name}</span>
              </div>
              <div>
                <span>Computer name:&nbsp;&nbsp;&nbsp;</span>
                <span className="font-medium text-[#212121]">
                  {image.computer_name}
                </span>
              </div>
              <div>
                <span>Estimate used:&nbsp;&nbsp;&nbsp;</span>
                <span>{image.estimate_used}</span>
              </div>
              <div>
                <span>Created at:&nbsp;&nbsp;&nbsp;</span>
                <span>{formatDate(image.createdAt)}</span>
              </div>
              <div>
                <span>Pass RDP:&nbsp;&nbsp;&nbsp;</span>
                <span className="font-medium text-[#212121]">
                  {image.passRdp}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          {image.is_installed === 1 && (
            <div
              onClick={handleChooseRouter}
              className="flex gap-2 items-center mt-2 md:absolute right-10 top-14 text-white cursor-pointer w-fit bg-[#42A5F5] hover:bg-[#0f63a7] hover:text-white py-1 px-2 rounded"
            >
              <img
                className="w-[35px] h-[35px] mx-auto"
                src={Images.Internet.default}
                alt=""
              />
              Chọn đường truyền Internet
            </div>
          )}

          <div className="max-md:mt-4 max-md:align-middle lg:absolute flex items-center gap-4 right-10 bottom-5">
            {image.is_installed === 1 && (
              <div
                className="cursor-pointer [&_.inner-item]:hover:text-[#0D47A1] p-1 border border-solid border-transparent hover:border-gray-500 rounded"
                onClick={() => onExtendImage(image)}
              >
                <div>
                  <img
                    className="w-[35px] h-[35px] mx-auto"
                    src={Images.Sync.default}
                    alt=""
                  />
                </div>
                <div className="text-[#2196F3] text-center text-xs font-medium mt-2 inner-item">
                  Gia hạn
                </div>
              </div>
            )}
            {image.is_installed === 1 && (
              <div
                className="cursor-pointer [&_.inner-item]:hover:text-[#0D47A1] p-1 border border-solid border-transparent hover:border-gray-500 rounded"
                onClick={() => onStartImage(image._id)}
              >
                <div>
                  <img
                    className="w-[35px] h-[35px] mx-auto"
                    src={Images.Power.default}
                    alt=""
                  />
                </div>
                <div className="text-[#2196F3] text-center text-xs font-medium mt-2 inner-item">
                  Start
                </div>
              </div>
            )}
            {image.is_installed === 1 && (
              <div
                className="cursor-pointer [&_.inner-item]:hover:text-[#0D47A1] p-1 border border-solid border-transparent hover:border-gray-500 rounded"
                onClick={() => onReConnect(image._id)}
              >
                <div>
                  <img
                    className="w-[35px] h-[35px] mx-auto"
                    src={Images.ReConnect.default}
                    alt=""
                  />
                </div>
                <div className="text-[#2196F3] text-center text-xs font-medium mt-2 inner-item">
                  Kết nối lại <br /> (nếu reset máy tính)
                </div>
              </div>
            )}
            <div
              className="cursor-pointer [&_.inner-item]:hover:text-[#0D47A1] p-1 border border-solid border-transparent hover:border-gray-500 rounded"
              onClick={handleBootImage}
            >
              <div>
                <img
                  className={clsx("w-[35px] h-[35px] mx-auto", {
                    "image-rotate":
                      image.is_booting === 1 && image.is_installed === 0,
                  })}
                  src={getImageBoot()}
                  alt=""
                />
              </div>
              <div className="text-[#2196F3] text-xs font-medium mt-2 inner-item">
                {getLabelBoot()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComputerCard;
