import { DEFAULT_PAGE, OrderStatus } from "@app/constants";
import { CreateOrderRequest, Image, Order, OrderResponse } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _OrderService {
  public getOrders(page = DEFAULT_PAGE, status?: OrderStatus) {
    return HttpService.get<OrderResponse>("/orders", {
      queryParams: {
        page,
        status,
      },
    });
  }

  public createOrder(createOrderRequest: CreateOrderRequest) {
    return HttpService.post<Image>("/orders", {
      body: { ...createOrderRequest },
    });
  }

  public getRDPFile(imageId: string) {
    return HttpService.requestDownload(`/orders/file/${imageId}`);
  }

  public getOrderByImageId(imageId: string) {
    return HttpService.get<Order>(`/orders/image/${imageId}`);
  }

  public extendOrder(createOrderRequest: CreateOrderRequest) {
    return HttpService.patch<Order>("/orders/extend", {
      body: { ...createOrderRequest },
    });
  }
}

const OrderService = new _OrderService();

export default OrderService;
