import * as RemoveIconThin from "@assets/images/svg/remove-icon-thin.svg";
import * as EyeIcon from "@assets/images/svg/eye.svg";
import * as EyeSlashIcon from "@assets/images/svg/eye-slash.svg";
import * as Background from "@assets/images/background.jpg";
import * as AvatarDefaultIcon from "@assets/images/svg/avatar-default.svg";
import * as NvidiaIcon from "@assets/images/svg/nvidia.svg";
import * as MoneyIcon from "@assets/images/svg/money.svg";
import * as RocketIcon from "@assets/images/svg/rocket.svg";
import * as RedshiftIcon from "@assets/images/svg/redshift.svg";
import * as BlenderIcon from "@assets/images/svg/blender.svg";
import * as OctaneIcon from "@assets/images/svg/octane.svg";
import * as IrayIcon from "@assets/images/svg/iray.svg";
import * as LogoPcRender from "@assets/images/logo.png";
import * as LogoPcRenderWhite from "@assets/images/logo-white.png";
import * as IdentityCardIcon from "@assets/images/svg/identity-card.svg";
import * as Log from "@assets/images/log.png";
import * as Clipboard from "@assets/images/clipboard.png";
import * as RemoteDesktop from "@assets/images/remote-desktop.png";
import * as Power from "@assets/images/power.png";
import * as Sync from "@assets/images/sync.png";
import * as MenuIcon from "@assets/images/menu.png";
import * as HomeIcon from "@assets/images/home.png";
import * as SandClockIcon from "@assets/images/sand-clock.png";
import * as ReConnect from "@assets/images/reconnect.png";
import * as Internet from "@assets/images/ip-address.png";

export const Images = {
  RemoveIconThin,
  EyeIcon,
  EyeSlashIcon,
  Background,
  AvatarDefaultIcon,
  NvidiaIcon,
  MoneyIcon,
  RocketIcon,
  RedshiftIcon,
  BlenderIcon,
  OctaneIcon,
  IrayIcon,
  LogoPcRender,
  LogoPcRenderWhite,
  IdentityCardIcon,
  Log,
  Clipboard,
  RemoteDesktop,
  Power,
  Sync,
  MenuIcon,
  HomeIcon,
  SandClockIcon,
  ReConnect,
  Internet,
};
